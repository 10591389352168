import { thunk } from 'easy-peasy';
import { IStoreModel, ITrading, TradeActionsAndThunks, TradeTypes } from '../../types';
import { ITradeItem, ITradingThunks } from '../../types';
import { TradingContract } from '../../blockchain/contracts/TradingContract';
import { JsonRpcSigner } from '@ethersproject/providers';
import { TokenContract } from '../../blockchain';
import { ethers } from 'ethers';
import tradingAPI from '../../api/trading/tradingAPI';
import { roundToDecimals } from 'utils/roundToDecimals';

export const thunks: ITradingThunks = {
  executeTrade: thunk<TradeActionsAndThunks, ITrading, unknown, IStoreModel>(
    async (actions, payload, { getStoreState }) => {
      actions.startTradeExecution();
      try {
        const ask = payload.type === TradeTypes.Ask;
        const wallet = getStoreState().blockchain.wallet;
        const tradingContract = new TradingContract(wallet?.provider?.getSigner() as JsonRpcSigner);
        /**
         * to omit undeflow error Error: fractional component exceeds decimals (fault="underflow", operation="parseFixed", code=NUMERIC_FAULT, version=bignumber/5.5.0)
         * we need to pass number with max 6 decimals
         */
        const amount = ethers.utils.parseUnits(roundToDecimals(payload.amount).toString(), 18);

        if (ask) {
          const tokenContract = new TokenContract(
            payload.address as string,
            wallet?.provider?.getSigner() as JsonRpcSigner,
          );
          const txApprove = await tokenContract.approveTrading(amount.toString());
          await txApprove.wait();
        }

        const totalPrice = ethers.utils.parseUnits(roundToDecimals(payload.tokenPrice * payload.amount).toString(), 18);

        const txResult = await tradingContract.execute(payload.offerId, amount, { value: totalPrice });

        await tradingAPI.completeTrade(payload.id, txResult.hash);

        actions.stopTradeExecution();

        return true;
      } catch (e) {
        console.error(e);
        actions.stopTradeExecution();

        return false;
      }
    },
  ),
  createTrade: thunk<TradeActionsAndThunks, ITradeItem, unknown, IStoreModel>(
    async (actions, payload, { getStoreState }) => {
      const wallet = getStoreState().blockchain.wallet;
      const tradingContract = new TradingContract(wallet?.provider?.getSigner() as JsonRpcSigner);
      const tokenContract = new TokenContract(
        payload.address as string,
        wallet?.provider?.getSigner() as JsonRpcSigner,
      );
      actions.startTradeCreation();
      try {
        const address = payload.address as string;
        /**
         * to omit undeflow error Error: fractional component exceeds decimals (fault="underflow", operation="parseFixed", code=NUMERIC_FAULT, version=bignumber/5.5.0)
         * we need to pass number with max 6 decimals
         */
        const amount = ethers.utils.parseUnits(roundToDecimals(payload.amount).toString(), 18);
        const price = ethers.utils.parseUnits(roundToDecimals(payload.totalPrice).toString(), 18);
        const bid = payload.type === TradeTypes.Bid;
        if (bid) {
          const txApprove = await tokenContract.approveTrading(payload.amount.toString());
          await txApprove.wait();
        }
        const txResult = await tradingContract.create(address, amount, price, bid);

        await tradingAPI.createTrade({ ...payload, ...txResult });

        actions.stopTradeCreation();

        return true;
      } catch (e) {
        // todo if approve fails - should be handled and shown to user
        console.log(e);
        actions.stopTradeCreation();
        actions.setTradeCreationError(true);

        return false;
      }
    },
  ),
};
