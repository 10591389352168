import moment from 'moment';
import Countdown, { CountdownRenderProps } from 'react-countdown';
import { useTranslation } from 'react-i18next';

import styles from './CountdownComponent.module.scss';

interface IPropsTypes {
  /**
   * Start Time of the auction in milliseconds
   *
   * @type {?number}
   */
  startTime?: number;
  /**
   * End Time of the auction in milliseconds
   *
   * @type {?number}
   */
  endTime?: number;
  text?: string;
}

const CountdownComponent = ({ startTime = 0, endTime = 0, text = 'auctionEnded' }: IPropsTypes) => {
  const { t } = useTranslation('views/auction');

  const renderer = ({ formatted, completed, days }: CountdownRenderProps) => {
    if (startTime > new Date().valueOf()) {
      return <span>Start time: {moment(startTime).format('YYYY-MM-DD HH:mm:ss')}</span>;
    }

    if (completed) {
      return (
        <>
          {t(`createAuction.${text}`)}: <span className={styles.text}>{moment(endTime).format('ll')}</span>
        </>
      );
    } else {
      return (
        <>
          {t('createAuction.timeLeft')}:
          <span className={styles.text}>{`${days ? days + 'd' : ''} ${formatted.hours}:${formatted.minutes}:${
            formatted.seconds
          }`}</span>
        </>
      );
    }
  };

  return <Countdown date={endTime} renderer={renderer} />;
};

export default CountdownComponent;
