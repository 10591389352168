import API from '../API';
import appConfig from '../../config/appConfig';
import { IFilterRequestParams } from '../../types/api/common';
import {
  IAdminUpdateCompanyRequestBody,
  ICompanyResponseBody,
  IPendingCompaniesResponseBody,
} from '../../types/api/admin/companies';

const adminCompaniesAPI = {
  getPendingCompanies(params: IFilterRequestParams): Promise<IPendingCompaniesResponseBody> {
    return API.get<IPendingCompaniesResponseBody>(`${appConfig.serviceUrl}/api/admin/companies`, {
      params,
    }).then((response) => response.data);
  },

  updateCompany(companyId: string | number, body: IAdminUpdateCompanyRequestBody): Promise<void> {
    return API.put<IAdminUpdateCompanyRequestBody, void>(
      `${appConfig.serviceUrl}/api/admin/companies/${companyId}`,
      body,
    ).then((response) => response.data);
  },

  getCompanyById(companyId: string | number): Promise<ICompanyResponseBody> {
    return API.get<any>(`${appConfig.serviceUrl}/api/admin/companies/${companyId}`).then((response) => response.data);
  },

  deleteCompanyById(companyId: string | number): Promise<ICompanyResponseBody> {
    return API.delete<any>(`${appConfig.serviceUrl}/api/admin/companies/${companyId}`).then(
      (response) => response.data,
    );
  },

  markForDeletion(companyId: number, body: { markedForDeletion: boolean }): Promise<void> {
    return API.patch<{ markedForDeletion: boolean }, void>(
      `${appConfig.serviceUrl}/api/admin/companies/${companyId}/mark-for-deletion`,
      body,
    ).then((response) => response.data);
  },
};

export default adminCompaniesAPI;
