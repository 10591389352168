import { thunk } from 'easy-peasy';

import companyAPI from 'api/company/companyAPI';
import { IStoreModel, CompanyActionsAndThunks, DocumentType, ICompanyThunks, ICompanyForm } from 'types';
import { buildCreateDocumentsRequestBody } from 'utils';

export const thunks: ICompanyThunks = {
  loadCompany: thunk<CompanyActionsAndThunks>(async (actions) => {
    actions.setCompanyLoading(true);
    try {
      const company = await companyAPI.getMyCompany();
      actions.setCompany(company);
    } finally {
      actions.setCompanyLoading(false);
    }
  }),
  updateCompany: thunk<CompanyActionsAndThunks, ICompanyForm, unknown, IStoreModel>(
    async (actions, payload, { getStoreActions }) => {
      const storeActions = getStoreActions();
      const { companyLegalDocuments = [], founderLegalDocuments = [], ...body } = payload;

      const uploadUrls = await companyAPI.updateMyCompany({
        ...body,
        documents: [
          ...buildCreateDocumentsRequestBody(companyLegalDocuments, DocumentType.CompanyLegal),
          ...buildCreateDocumentsRequestBody(founderLegalDocuments, DocumentType.FounderLegal),
        ],
      });

      if (uploadUrls) {
        await storeActions.document.uploadDocumentInputs({
          uploadUrls,
          documents: [...companyLegalDocuments, ...founderLegalDocuments],
        });
      }
    },
  ),
  submitCompany: thunk<CompanyActionsAndThunks>(async () => {
    await companyAPI.submitMyCompany();
  }),
};
