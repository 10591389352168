import { ProjectStandards, ProjectStatus } from 'constants/index';
import { ICreateDocumentRequestBody, IDocumentResponseBody } from './document';
import { IIssueTokens } from './issue-tokens';
import { IUserProfileResponseBody } from './user';

export interface IProjectsCount {
  total: number;
}

export interface IProjectsListResponseBody {
  id: number;
  parentId?: number;
  name: string;
  country: string;
  emissionReductions: string;
  price: number;
  projectType: ProjectTypes;
  coverImage: string;
  isFavorite?: boolean;
  standard: ProjectStandards | null;
  auction: IAuctionTypes | null;
}

export interface IProjectDetailsResponseBody extends IProjectsListResponseBody {
  userPosition: string;
  status: ProjectStatus;
  companyName: string;
  description: string;
  province: string;
  streetAddress: string;
  postCode: string;
  city: string;
  phone: string;
  website: string;
  methodology: ProjectMethodologies;
  publicProjectId?: number;
  documents: Array<IDocumentResponseBody>;
  createdAt: string;
  reviewComment?: string;
  issueTokensRequest: IIssueTokens | null;
  tokenName: string | null;
  tokenTicker: string | null;
  tokenAddr: string | null;
  lat: number;
  lon: number;
  user: IUserProfileResponseBody;
  isFavorite?: boolean;
  auction: IAuctionTypes;
  markedForDeletion: boolean;
}
export interface ITokenTickerExistsResponseBody {
  message: string;
  exists: boolean;
}

export interface IAuctionTypes {
  startTime: number;
  endTime: number;
  auctionId: number;
  auctionStarter: string;
  amount?: number;
  startPrice?: number;
}
export interface IProjectUpdateRequestBody {
  name: string;
  description: string;
  projectType: ProjectTypes;
  emissionReductions: string;
  price: number;
  country: string;
  province: string;
  streetAddress: string;
  postCode: string;
  city: string;
  phone: string;
  website: string;
  standard?: ProjectStandards;
  methodology: ProjectMethodologies;
  documents: Array<ICreateDocumentRequestBody>;
}

export interface IProjectFavoriteRequestBody {
  projectId: number;
}
export interface IProjectSubmitAuditDocumentsRequestBody {
  documents: Array<ICreateDocumentRequestBody>;
}

export enum ProjectTypes {
  Forestry = 'Forestry',
  EnergyDemand = 'Energy Demand',
  EnergyDistribution = 'Energy Distribution',
  EnergyIndustries = 'Energy Industries',
  ChemicalIndustry = 'Chemical Industry',
  FugitiveEmissions = 'Fugitive Emissions',
  Livestock = 'Livestock',
  ManufacturingIndustries = 'Manufacturing Industries',
  MetalProduction = 'Metal Production',
  Mining = 'Mining',
  Transport = 'Transport',
  WasteHandlingAndDisposal = 'Waste handling and disposal',
}

export enum ProjectMethodologies {
  VM0001 = 'VM0001',
  VM0002 = 'VM0002',
  VM0003 = 'VM0003',
  VM0004 = 'VM0004',
  VM0005 = 'VM0005',
  VM0006 = 'VM0006',
  VM0007 = 'VM0007',
  VM0008 = 'VM0008',
  VM0009 = 'VM0009',
  VM0010 = 'VM0010',
  VM0011 = 'VM0011',
  VM0012 = 'VM0012',
  VM0013 = 'VM0013',
  VM0014 = 'VM0014',
  VM0015 = 'VM0015',
  VM0016 = 'VM0016',
  VM0017 = 'VM0017',
  VM0018 = 'VM0018',
  VM0019 = 'VM0019',
  VM0020 = 'VM0020',
  VM0021 = 'VM0021',
  VM0022 = 'VM0022',
  VM0023 = 'VM0023',
  VM0024 = 'VM0024',
  VM0025 = 'VM0025',
  VM0026 = 'VM0026',
  VM0027 = 'VM0027',
  VM0028 = 'VM0028',
  VM0029 = 'VM0029',
  VM0030 = 'VM0030',
  VM0031 = 'VM0031',
  VM0032 = 'VM0032',
  VM0033 = 'VM0033',
  VM0034 = 'VM0034',
  VM0035 = 'VM0035',
  VM0036 = 'VM0036',
  VM0037 = 'VM0037',
  VM0038 = 'VM0038',
  VM0039 = 'VM0039',
  VM0040 = 'VM0040',
  VM0041 = 'VM0041',
  VM0042 = 'VM0042',
  VM0043 = 'VM0043',
  VMR0001 = 'VMR0001',
  VMR0002 = 'VMR0002',
  VMR0003 = 'VMR0003',
  VMR0004 = 'VMR0004',
  VMR0005 = 'VMR0005',
  VMR006 = 'VMR006',
}
