import { thunk } from 'easy-peasy';
import pick from 'lodash.pick';

import {
  AdminCompaniesActionsAndThunks,
  IAdminCompaniesThunks,
  IRejectCompanyPayload,
  ICompanyResponseBody,
  IFilterRequestParams,
  IStoreModel,
  IMarkCompanyForDeletion,
  IDeleteCompanyPayload,
  TradeStatus,
} from 'types';
import adminCompaniesAPI from 'api/admin/adminCompaniesAPI';
import { CompanyStatus } from 'constants/company';
import { ErrorTypes } from 'constants/index';
import { PlatformContract } from 'blockchain';
import { TransactionReceiptStatus } from 'constants/transaction';
import tradingAPI from 'api/trading/tradingAPI';
import { TradingContract } from 'blockchain/contracts/TradingContract';
import { verifyTransaction } from 'utils/verifyTransaction';

export const thunks: IAdminCompaniesThunks = {
  loadPendingCompanies: thunk<AdminCompaniesActionsAndThunks, IFilterRequestParams, unknown, IStoreModel>(
    async (actions, payload) => {
      actions.startPendingCompaniesLoading();
      try {
        const pendingCompanies = await adminCompaniesAPI.getPendingCompanies(
          pick(payload, ['name', 'status', 'page', 'limit', 'createdAt']),
        );
        actions.setPendingCompanies(pendingCompanies);
      } finally {
        actions.stopPendingCompaniesLoading();
      }
    },
  ),

  startCompanyReview: thunk<AdminCompaniesActionsAndThunks, string, unknown, IStoreModel>(async (actions, payload) => {
    await adminCompaniesAPI.updateCompany(payload, { status: CompanyStatus.InReview });
  }),

  approveCompany: thunk<AdminCompaniesActionsAndThunks, ICompanyResponseBody, unknown, IStoreModel>(
    async (actions, payload, { getStoreState }) => {
      const wallet = getStoreState().blockchain.wallet;

      if (!wallet.provider) {
        throw new Error(ErrorTypes.NoMetamask);
      }

      const contract = new PlatformContract(wallet.provider.getSigner());

      if (!payload.txHash) {
        const tx = await contract.registerCompany(payload);

        const receipt = await tx.wait();

        if (receipt.status === TransactionReceiptStatus.Success) {
          await adminCompaniesAPI.updateCompany(payload.id, { status: CompanyStatus.Approved, txHash: tx.hash });
        }
      } else {
        await contract.addCompanyDocuments(payload);
        await adminCompaniesAPI.updateCompany(payload.id, { status: CompanyStatus.Approved });
      }
    },
  ),

  rejectCompany: thunk<AdminCompaniesActionsAndThunks, IRejectCompanyPayload, unknown, IStoreModel>(
    async (actions, payload) => {
      await adminCompaniesAPI.updateCompany(payload.id, {
        status: CompanyStatus.Rejected,
        reason: payload.reason,
      });
    },
  ),

  deleteCompany: thunk<AdminCompaniesActionsAndThunks, IDeleteCompanyPayload, unknown, IStoreModel>(
    async (actions, payload, { getStoreState }) => {
      const wallet = getStoreState().blockchain.wallet;

      if (!wallet.provider) {
        throw new Error(ErrorTypes.NoMetamask);
      }

      const contract = new PlatformContract(wallet.provider.getSigner());

      const consumerInfo = await contract.printConsumerInfo(payload.accountAddress);

      if (consumerInfo?.length) {
        const isSuccess = await verifyTransaction(contract.removeConsumer(payload.accountAddress));

        if (!isSuccess) {
          throw new Error(ErrorTypes.RemoveConsumer);
        }
      }

      const tradings = await tradingAPI.getList({
        userId: payload.userId,
        status: TradeStatus.Active,
      });

      const tradingItems = tradings.items;

      const tradingContract = new TradingContract(wallet.provider.getSigner());

      if (tradingItems.length > 0) {
        await tradingContract.batchDeleteSellBuy(Array.from(tradingItems, (item) => item.offerId));
      }

      await adminCompaniesAPI.deleteCompanyById(payload.id);
    },
  ),

  markForDeletion: thunk<AdminCompaniesActionsAndThunks, IMarkCompanyForDeletion, unknown, IStoreModel>(
    async (actions, payload) => {
      await adminCompaniesAPI.markForDeletion(payload.id, { markedForDeletion: payload.markedForDeletion });
    },
  ),
};
