export const DOCUMENT_ACCEPT = '.doc,.docx,.pdf';

export const DOCUMENTS_MIMETYPES = [
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
];
export const IMAGE_ACCEPT = '.png,.jpg,.jpeg';

export const IMAGE_MIMETYPES = ['image/jpeg', 'image/png', 'image/jpg'];
