export enum ErrorTypes {
  NoMetamask = 'noMetamask',
  NoTxHashError = 'noTxHashError',
  WalletNotConnected = 'walletNotConnected',
  AdminAccountAddress = 'adminAccountAddress',
  AdminPermissionError = 'adminPermissionError',
  AdminAccountNotExist = 'adminAccountNotExist',
  UnsuccessfulTransaction = 'unsuccessfulTransaction',
  DeleteAuction = 'deleteAuction',
  DeleteBids = 'deleteBids',
  DeleteProject = 'deleteProject',
  GrantRole = 'grantRole',
  RevokeRole = 'revokeRole',
  RegisterCompany = 'registerCompany',
  RemoveConsumer = 'removeConsumer',
}
