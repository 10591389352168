import API from '../API';
import appConfig from '../../config/appConfig';
import {
  IAdminUpdateProjectRequestBody,
  IPendingProjectsResponseBody,
  IFilterRequestParams,
  IProjectDetailsResponseBody,
  ITokensRequestsResponseBody,
  IIssueTokensUpdateRequest,
  IIssueTokens,
} from 'types';

const adminProjectsAPI = {
  getPendingProjects(params: IFilterRequestParams): Promise<IPendingProjectsResponseBody> {
    return API.get<IPendingProjectsResponseBody>(`${appConfig.serviceUrl}/api/admin/projects`, {
      params,
    }).then((response) => response.data);
  },

  updateProject(projectId: string | number, body: IAdminUpdateProjectRequestBody): Promise<void> {
    return API.put<IAdminUpdateProjectRequestBody, void>(
      `${appConfig.serviceUrl}/api/admin/projects/${projectId}`,
      body,
    ).then((response) => response.data);
  },

  deleteProject(projectId: number) {
    return API.delete<void>(`${appConfig.serviceUrl}/api/admin/projects/${projectId}`).then(
      (response) => response.data,
    );
  },

  markForDeletion(projectId: number, body: { markedForDeletion: boolean }): Promise<void> {
    return API.patch<{ markedForDeletion: boolean }, void>(
      `${appConfig.serviceUrl}/api/admin/projects/${projectId}/mark-for-deletion`,
      body,
    ).then((response) => response.data);
  },

  getProjectById(projectId: string | number): Promise<IProjectDetailsResponseBody> {
    return API.get<IProjectDetailsResponseBody>(`${appConfig.serviceUrl}/api/admin/projects/${projectId}`).then(
      (response) => response.data,
    );
  },

  getTokensRequests(params: IFilterRequestParams): Promise<ITokensRequestsResponseBody> {
    return API.get<ITokensRequestsResponseBody>(`${appConfig.serviceUrl}/api/admin/projects/issue-tokens-requests`, {
      params,
    }).then((response) => response.data);
  },

  getProjectTokenRequest(id: number): Promise<IIssueTokens> {
    return API.get<IIssueTokens>(`${appConfig.serviceUrl}/api/admin/projects/issue-tokens-requests/${id}`).then(
      (response) => response.data,
    );
  },

  updateTokenRequest(tokenRequestId: number, body: IIssueTokensUpdateRequest): Promise<IIssueTokens> {
    return API.put<IIssueTokensUpdateRequest, IIssueTokens>(
      `${appConfig.serviceUrl}/api/admin/projects/issue-tokens-requests/${tokenRequestId}`,
      body,
    ).then((response) => response.data);
  },
};

export default adminProjectsAPI;
