import { thunk } from 'easy-peasy';
import pick from 'lodash.pick';

import {
  AdminTokensRequestsActionsAndThunks,
  IAdminTokensRequestsThunks,
  IApproveTokenRequestPayload,
  IFilterRequestParams,
  IIssueTokensUpdateRequest,
  IRejectTokenRequestPayload,
  IssueTokenStatus,
  IssueTokenType,
  IStoreModel,
  tokenRequestTypeMap,
} from 'types';
import adminProjectsAPI from 'api/admin/adminProjectsAPI';
import { ErrorTypes } from 'constants/index';
import { PlatformContract } from 'blockchain';
import { TransactionReceiptStatus } from 'constants/transaction';

export const thunks: IAdminTokensRequestsThunks = {
  loadTokensRequests: thunk<AdminTokensRequestsActionsAndThunks, IFilterRequestParams, unknown, IStoreModel>(
    async (actions, payload) => {
      actions.setTokensRequestsLoading(true);
      try {
        const tokensRequests = await adminProjectsAPI.getTokensRequests(
          pick(payload, ['statuses', 'type', 'page', 'limit', 'name', 'createdAt']),
        );
        actions.setTokensRequestsList(tokensRequests);
      } finally {
        actions.setTokensRequestsLoading(false);
      }
    },
  ),

  loadIssueTokensRequest: thunk<AdminTokensRequestsActionsAndThunks, number, unknown, IStoreModel>(
    async (actions, payload) => {
      actions.setTokensRequestsLoading(true);
      try {
        const projectTokenRequest = await adminProjectsAPI.getProjectTokenRequest(payload);
        actions.setIssueTokensRequest(projectTokenRequest);
      } finally {
        actions.setTokensRequestsLoading(false);
      }
    },
  ),

  startTokenRequestReview: thunk<AdminTokensRequestsActionsAndThunks, number, IIssueTokensUpdateRequest, IStoreModel>(
    async (actions, payload) => {
      await adminProjectsAPI.updateTokenRequest(payload, { status: IssueTokenStatus.InReview });
    },
  ),

  approveTokenRequest: thunk<AdminTokensRequestsActionsAndThunks, IApproveTokenRequestPayload, number, IStoreModel>(
    async (actions, payload, { getStoreState }) => {
      const { id, amount, type, projectId, accountAddress } = payload;
      const wallet = getStoreState().blockchain.wallet;

      if (!wallet.provider) {
        throw new Error(ErrorTypes.NoMetamask);
      }

      const project = await adminProjectsAPI.getProjectById(projectId);
      const contract = new PlatformContract(wallet.provider.getSigner());

      if (type === tokenRequestTypeMap[IssueTokenType.issueTokensRequest]) {
        const tx = await contract.mint(accountAddress, amount);

        const receipt = await tx.wait();

        if (!tx.hash) {
          throw new Error(ErrorTypes.NoTxHashError);
        }

        if (receipt.status === TransactionReceiptStatus.Success) {
          await adminProjectsAPI.updateTokenRequest(id, { status: IssueTokenStatus.Approved, txHash: tx?.hash });
        }
      } else {
        await contract.addProjectDocuments(project);
        await adminProjectsAPI.updateTokenRequest(id, { status: IssueTokenStatus.Approved });
      }
    },
  ),

  rejectTokenRequest: thunk<AdminTokensRequestsActionsAndThunks, IRejectTokenRequestPayload, number, IStoreModel>(
    async (actions, payload) => {
      await adminProjectsAPI.updateTokenRequest(payload.id, {
        status: IssueTokenStatus.Rejected,
        reviewComment: payload.reason,
      });
    },
  ),
};
