import { useMemo } from 'react';
import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, matchPath, useHistory } from 'react-router-dom';

import { INavMenuItem } from 'types';
import IconSvg from 'components/icons';

import styles from './NavMenu.module.scss';

interface IPropsTypes {
  items: Array<INavMenuItem>;
}

const NavMenu = ({ items }: IPropsTypes) => {
  const { t } = useTranslation('views/common');
  const history = useHistory();
  const currentPath = history.location.pathname;

  const selectedKeys = useMemo(() => {
    return items.filter(({ path }) => !!matchPath(currentPath, { path, exact: true })).map(({ key }) => key);
  }, [currentPath, items]);

  return (
    <Menu theme='dark' mode='inline' selectedKeys={selectedKeys} className={styles.menu}>
      {items.map((item: INavMenuItem) => {
        let query = '';

        if (item.query) {
          const keys = Object.keys(item.query);
          const values = Object.values(item.query);
          query = keys.map((key, index) => `${key}=${values[index]}`).join('&');
        }
        const path = query ? `${item.path}?${query}` : item.path;

        return (
          <Menu.Item key={item.key} icon={<IconSvg icon={item.icon} />}>
            {/* do not allow to navigate again to the same page */}
            {selectedKeys[0] === item.key ? (
              <a>{t(`navBar.menu.${item.name}`)}</a>
            ) : (
              <Link to={path}>{t(`navBar.menu.${item.name}`)}</Link>
            )}
          </Menu.Item>
        );
      })}
    </Menu>
  );
};

export default NavMenu;
