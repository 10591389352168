export enum SocketEvents {
  Channels = 'channels',
  AuctionsInfo = 'auctions:info',
  AuctionsNew = 'auctions:new',
  AuctionBid = 'auctions:bid',
  AuctionEndInfo = 'auctions:end:info',
  AuctionEnd = 'auctions:end',
  AuctionBidNew = 'auctions:bid:new',
  BurnsInfo = 'burns:info',
  BurnsNew = 'burns:new',
}
