import { TransactionReceipt, TransactionResponse } from '@ethersproject/abstract-provider';
import { TransactionReceiptStatus } from 'constants/transaction';

export async function verifyTransaction(tx: Promise<TransactionResponse>): Promise<boolean> {
  try {
    const receipt: TransactionReceipt = await (await tx).wait();

    if (receipt.status === TransactionReceiptStatus.Success) {
      return true;
    }

    return false;
  } catch (error) {
    const err = error as Error & { code: number };

    // 4001 error code: metamask reject transaction
    if (err.code === 4001) {
      throw error;
    }

    return false;
  }
}
