export enum Environment {
  Production = 'production',
  Development = 'development',
  Staging = 'staging',
}

export const REQUEST_TIMEOUT = 20000;

export const DEFAULT_PAGE_SIZE = 10;

export const VERIFICATION_MESSAGE_REFRESH_INTERVAL = 4 * 60 * 1000; // 4 minutes

export const TABS_ITEMS_PER_PAGE = 5;

export const MAX_SUGGESTION_INDEX = 4;

export const GAS_LIMIT = 1_000_000;

// we need to have gap to proceed a transaction
export const AUCTION_START_GAP_TIME_IN_MINUTES = 2;
